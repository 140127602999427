<template>
    <vx-card class="pb-10">
        <div class="">
            <button
                @click="downloadFileFormat()"
                class="vs-component vs-button vs-button-info vs-button-relief rounded-full float-right"
            >
                <img :src="require(`@/assets/icons/DownloadFileIcon.svg`)" width="16px" class="inline-block align-middle mr-2" />
                {{ this.$t('Descargar formato de Cargar Masiva') }}
            </button>
        </div>

        <h1>{{ this.$t('Carga de Pre Ordenes') }}</h1>
        <span>
            {{ this.$t('Sube tu archivo con los datos completos') }}
        </span>

        <div class="vx-row mt-5">
            <div class="vx-col lg:w-1/2 sm:w-full">
                <div
                    class="border-2 border-dashed rounded-lg text-center pt-32 pb-32 cursor-pointer"
                    @click="clickFileButton()"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                >
                    
                    <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" ref="file" accept=".xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @change="onChange()" />

                    <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="64px" class="inline-block align-middle pb-5" /><br/>
                    <b>{{ this.$t('Carga tu archivo') }}</b><br/>
                    <span class="font-light">{{ this.$t('Archivos soportados:') }}</span><br/>
                    <span class="font-light">{{ this.$t('.xlsx o .xls') }}</span><br/>
                    <vs-button color="#d8f8ff" text-color="#33bdd4" class="rounded-full">
                        {{ this.$t('Buscar Archivo') }}
                    </vs-button>
                    
                </div>
            </div>

            <div class="vx-col lg:w-1/2 sm:w-full sm:mt-10">

                <!-- lista de archivos -->
                <VuePerfectScrollbar style="height: 85%;">
                    <b>{{ this.$t('Archivos Subidos') }} </b><br/><br/>
                
                    <vx-card  v-if="files.length === 0" class="text-center pt-3 pb-3">
                        <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="32px" class="inline-block align-middle mr-5" />
                        {{ this.$t('No se encontraron archivos subidos') }}
                    </vx-card>

                    <vx-card v-for="(file, i) in files" v-bind:key="i" class="mb-1">
                        <div class="vx-row">
                            <div class="vx-col w-1/6 align-middle">
                                <img :src="require(`@/assets/icons/ExcelIcon.svg`)" width="64px" />
                            </div>

                            <div class="vx-col w-5/6">
                                <div class="vx-row">
                                    <div class="vx-col w-3/4">
                                        {{ file.file.name }}
                                        <p v-if="!file.loadError">Subiendo {{ file.progress }}%</p>
                                    </div>

                                    <div class="vx-col w-1/6">
                                        <vs-button  color="transparent" @click="removefile(i)" text-color="#666">
                                            <feather-icon icon='XIcon'  />
                                        </vs-button>
                                    </div>

                                </div>
                                
                                <p v-if="file.loadError" class="text-danger">Error en la carga</p>

                                <vs-progress :height="12" :percent="file.progress" :color="file.loadError ? 'danger' : 'success'"></vs-progress>
                            </div>

                            <div class="vx-col w-2">
                                
                            </div>
                        </div>

                    </vx-card>

                </VuePerfectScrollbar>

                <div class="w-full mt-5">
                    <vs-button color="#00c8ea" text-color="white" class="rounded-full float-right" :disabled="files.length === 0" @click="saveFiles()">
                        Cargar Archivos
                    </vs-button>
                </div>
                
            </div>
            
        </div>
    </vx-card>
</template>

<script lang="ts">
import Vue from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default Vue.extend({

    data() {
        return {
            files: [],
        }
    },

    watch: {
        files: {
            deep: true,
            handler( val ) {
                const uploaded = val.filter( f => f.progress === 100 );
                // change route to show inventory list
                if( uploaded.length === this.files.length )
                    this.$router.push('/app/preorders/list');
                
            },
        }
    },

    methods: {
        clickFileButton() {
            this.$refs.file.click();
        },

        onChange() {
            const filename = this.$refs.file.files[0].name;
            const ext = filename.substr(filename.lastIndexOf('.') + 1);
            if( this.$refs.file.files[0].type !== 'application/vnd.ms-excel' && this.$refs.file.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                return this.$vs.notify({
                    title: 'Formato Incorrecto',
                    text: 'Cargue archivos .xlsx ó .xls solamente',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
            }
            if( ext !== 'xlsx' && ext !== 'xls'  ) {
                return this.$vs.notify({
                    title: 'Formato Incorrecto',
                    text: 'Cargue archivos .xlsx ó .xls solamente',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
            }
            const files = this.$refs.file.files;
            const filesMapped = [...files].map( file => {
                let ob = Object.assign( {}, file );
                
                ob.file = file;
                ob.progress = 0;
                ob.loadError = '';

                return ob;
            });
            
            this.files = filesMapped;
        },

        dragover( ev ) {            
            ev.preventDefault();
            
            if (!ev.currentTarget.classList.contains('bg-primary')) {
                ev.currentTarget.classList.remove('bg-gray-100');
                ev.currentTarget.classList.add('bg-primary');
            }
        },

        dragleave( ev ) {
            ev.currentTarget.classList.remove('bg-primary');
        },

        drop( ev ) {
            ev.preventDefault();

            this.$refs.file.files = ev.dataTransfer.files;
            this.onChange();
            ev.currentTarget.classList.remove('bg-primary');
        },

        removefile( index ) {
            this.files.splice( index, 1 );
        },

        saveFiles() {
            this.$store.dispatch( 'preorders/uploadPreOrder', this.files )
                .then( () => {
                    this.$vs.notify({
                        title: 'Archivos en Proceso de Guardado',
                        text: 'La preorden se cargará asincrónicamente, verifica su estado en el menú notificaciones. ',
                        iconPack: 'feather',
                        icon: 'icon-check',
                        color: 'primary',
                        time: 7000,
                    });
                    
                });
        },

        downloadFileFormat() {
            this.$vs.loading();
            this.$store.dispatch( 'getFileUrl', '/documents/example-formats/prepedidos_format.xlsx' )
            .then( url => {
                this.$vs.loading.close();
                if( url ) {
                    const link = document.createElement('a');
                    
                    link.setAttribute( 'download', 'true' );
                    link.href = url;
                    link.click();
                    link.remove();
                }
            });
        },

    },

    components: {
        VuePerfectScrollbar,
    }
});
</script>